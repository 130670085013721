
.top_select[data-v-92c7179e] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.btn_div[data-v-92c7179e] {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
[data-v-92c7179e] .el-textarea__inner {
  height: 100px !important;
}
