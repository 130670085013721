.top_select[data-v-0848b5f2] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.div_p[data-v-0848b5f2] {
  line-height: 30px;
  border-bottom: 1px solid #efefef;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.div_p[data-v-0848b5f2]:last-child {
  border-bottom: none;
}
.iconfont[data-v-0848b5f2] {
  font-size: 13px;
  margin-right: 4px;
}
.red-font[data-v-0848b5f2] {
  color: red;
}